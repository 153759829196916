import actions from "@/qiankun/actions";

let data = <any>actions.actions;
let request = data.request;

// 根据字典类型查询字典数据信息（旧版字典）
export function getDicts(dictType: any) {
  return request({
    url: "/system/dict/data/type/" + dictType,
    method: "get",
  });
}
