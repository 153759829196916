import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  createWebHashHistory,
} from "vue-router";
const routes: Array<RouteRecordRaw> = [
  // 登录页
  {
    path: "/",
    name: "main",
    redirect: "/home/main",
    component: () => import("@/views/largeS/index.vue"),
    children: [
      {
        path: "/home",
        name: "home",
        redirect: "/home/main",
        component: () => import("@/views/largeS/home/index.vue"),
        children: [
          {
            path: "/home/main",
            name: "home-main",
            meta: { pathShow: true },
            component: () => import("@/views/largeS/home/main/index.vue"),
          },
          {
            path: "/home/main/device-backup",
            name: "device-backup",

            component: () =>
              import("@/views/largeS/home/main/device-backup/index.vue"),
          },
          // {
          //   path: "/home/main/device-backup/hisAna",
          //   name: "hisAna",
          //   component: () =>
          //     import(
          //       "@/views/largeS/home/main/device-backup/right-one/history-analysis/index.vue"
          //     ),
          // },
        ],
      },
      // {
      //   path: "/product-manage",
      //   name: "product-manage",
      //   component: () =>
      //     import("@/views/largeS/product-manage/index.vue"),
      // },
      {
        path: "/health",
        name: "health",
        redirect: "/health/abnormal",
        component: () => import("@/views/largeS/health/index.vue"),
        children: [
          {
            path: "/health/abnormal",
            name: "abnormal",
            component: () => import("@/views/largeS/health/abnormal/index.vue"),
          },
          {
            path: "/health/replace",
            name: "replace",
            component: () => import("@/views/largeS/health/replace/index.vue"),
          },
          // {
          //   path: "/health/inspection",
          //   name: "inspect-main",
          //   component: () =>
          //     import(
          //       "@/views/largeS/health/inspection/index.vue"
          //     ),
          // },
          // {
          //   path: "/health/comRecord",
          //   name: "comRecord",
          //   component: () =>
          //     import(
          //       "@/views/largeS/health/abnormal/comRecord/index.vue"
          //     ),
          // },
        ],
      },
    ],
  },
  {
    path: "/system/carM",
    name: "carM",
    component: () => import("@/views/system/carM/index.vue"),
  },
  {
    path: "/system/classM",
    name: "classM",
    component: () => import("@/views/system/classM/index.vue"),
  },
  {
    path: "/system/mouldM",
    name: "mouldM",
    component: () => import("@/views/system/mouldM/index.vue"),
    // children: [
    //   {
    //     path: "/system/mouldM/detail",
    //     name: "MMDetail",
    //     component: () => import("@/views/system/mouldM/detail/index.vue"),
    //   },
    // ],
  },
  {
    path: "/system/mouldM/detail",
    name: "MMDetail",
    component: () => import("@/views/system/mouldM/detail/index.vue"),
  },
  {
    path: "/system/equipment",
    name: "equipment",
    component: () => import("@/views/system/equipment/index.vue"),
    // children: [
    //   {
    //     path: "/system/mouldM/detail",
    //     name: "MMDetail",
    //     component: () => import("@/views/system/equipment/detail/index.vue"),
    //   },
    // ],
  },
  {
    path: "/system/equipment/detail",
    name: "EMDetail",
    component: () => import("@/views/system/equipment/detail/index.vue"),
  },
  {
    path: "/system/configT",
    name: "configT",
    component: () => import("@/views/system/configT/index.vue"),
  },
];

// const router = createRouter({
//   // history:  createWebHistory(process.env.BASE_URL),
//   history: createWebHashHistory(),
//   routes,
// });

// export default router;

export { routes };
