const selectListPubic = (list: any, id: any) => {
  if (list && id != null) {
    let data = list.filter((item: any) => item.value == id)[0];
    return data ? data : { label: "" };
  } else {
    return { label: "" };
  }
};

export { selectListPubic };
